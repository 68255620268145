@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {}

.pageRoot {
    display: flex;
    padding-bottom: 140px;

    @media (--viewportLarge) {
        padding-bottom: 0;
    }
}

.loadingText {
    margin: 24px;
}

.errorText {
    color: var(--failColor);
    margin: 24px;
}

.sectionImages {
    overflow: hidden;
    border-radius: 20px;

    @media (--viewportMedium) {
        border-radius: 20px 0px 0px 20px;
    }
}

.threeToTwoWrapper {
    /* Layout */
    display: block;
    width: 100%;
    position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
    position: relative;
    /* allow positioning own listing action bar */
    padding-bottom: 66.6667%;
    /* 3:2 Aspect Ratio */
    background-color: var(--matterColorNegative);
    /* Loading BG color */

    /* Image carousel can be opened from the image, therefore we should show a pointer */
    cursor: pointer;

    @media (--viewportMedium) {
        padding-bottom: 0;
        /* No fixed aspect on desktop layouts */
        border-radius: 20px 0px 0px 20px;
    }
}

.actionBar {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 13px;
    left: 13px;
    width: calc(100% - 26px);
    color: var(--matterColorNegative);
    background-color: var(--matterColor);
    z-index: 1;
    /* bring on top of mobile image */

    /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
    cursor: initial;

    border-radius: var(--borderRadius);
}

.ownListingText {
    @apply --marketplaceH4FontStyles;
    margin: 14px 12px 11px 24px;

    @media (--viewportMedium) {
        margin: 25px 12px 22px 24px;
    }
}

.ownListingTextPendingApproval {
    color: var(--attentionColor);
}

.closedListingText {
    @apply --marketplaceH4FontStyles;
    margin: 14px 12px 11px 24px;
    text-align: center;
    width: 100%;

    @media (--viewportMedium) {
        margin: 25px 12px 22px 24px;
    }
}

.editListingLink {
    @apply --marketplaceH4FontStyles;
    flex-shrink: 0;
    margin: 0;
    padding: 14px 24px 11px 12px;
    color: var(--matterColorNegative);

    transition: var(--transitionStyleButton);

    &:hover {
        color: var(--matterColorLight);
        text-decoration: none;
    }

    @media (--viewportMedium) {
        margin: 0;
        padding: 25px 24px 22px 12px;
    }
}

.editIcon {
    margin: -6px 7px 0 0;
}

.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 600px;
    transition: var(--transitionStyleButton);

    @media (--viewportMedium) {
        position: static;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        height: calc(0.38 * 100vw);
        //max-height: 50vh;
        object-fit: cover;
        //border-radius: var(--borderRadius);
        border-radius: 20px 0px 0px 20px;

        &:hover {
            transform: scale(1.005);
            box-shadow: var(--boxShadowListingCard);
        }
    }

    @media (--viewportLarge) {
        /* height: calc(0.38 * 100vw); */
    }

    @media (--viewportLargeWithPaddings) {
        /* height: calc(0.33 * 100vw); */
    }

    @media (--viewportXLarge) {
        /* height: calc(0.17 * 100vw); */
    }
}

.viewPhotos {
    @apply --marketplaceTinyFontStyles;
    font-weight: var(--fontWeightMedium);

    /* Position and dimensions */
    position: absolute;
    bottom: 19px;
    right: 24px;
    margin: 0;
    padding: 8px 13px 6px 13px;

    /* Colors */
    background-color: var(--matterColorLight);

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    cursor: pointer;
    transition: var(--transitionStyleButton);

    &:hover {
        background-color: var(--marketplaceColor);
        color: var(--matterColorLight);
    }

    @media (--viewportMedium) {
        margin: 0;
    }
}

.carouselModalScrollLayer {
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
    width: 100%;
    height: 100%;
}

.contentContainer,
.thumbSection {
    @media (--viewportMedium) {
        max-width: 1056px;
        max-height: 600px;
        margin: 0 auto;
        padding: 0 24px;
        display: flex;
        position: relative;
    }

    @media (--viewportLarge) {
        max-width: 1128px;
        padding: 0 36px;
        margin: 0 auto 117px;
    }

    @media (--viewportXLarge) {
        max-width: 1056px;
        padding: 0;
    }
}

.thumbSection {
    padding: 18px;

    @media (--viewportMedium) {
        display: grid;
        grid-template-columns: 80% 20%;
        grid-column-gap: 5px;
        width: 100%;
        /* max-width: 100%; */
        margin-top: 50px;
    }

    @media (--viewportLarge) {
        margin: 50px auto 0;
        /* width: auto; */
    }

    @media (--viewportXLarge) {
        /* width: auto; */
    }
}

.rightImgGellery {
    display: none;
    max-height: 600px;

    @media (--viewportMedium) {
        width: 100%;
        display: inline-flex;
        align-content: space-between;
        height: calc(0.38 * 100vw);
        /* overflow: hidden; */
        flex-direction: column;
        flex-wrap: nowrap;
    }

    &>.ImgGelleryThumb:first-child,
    &>.ImgGelleryThumb:nth-child(2) {
        padding-bottom: 5px;
    }
}

.ImgGelleryThumb {
    cursor: pointer;
    object-fit: cover;

    @media (--viewportMedium) {
        width: 100%;
        border-radius: 0px 20px 20px 0px;
        height: 33.33%;
    }

    @media (--viewportLarge) {}
}

.mainContent {
    flex-basis: 100%;
    margin-bottom: 23px;

    @media (--viewportMedium) {
        margin-top: 50px;
        margin-bottom: 51px;
        flex-shrink: 0;
    }

    @media (--viewportLarge) {
        margin-top: 50px;
        margin-bottom: 0px;
        /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
        max-width: calc(100% - 433px);
        flex-basis: calc(100% - 433px);
        flex-shrink: 0;
        flex-grow: 1;
    }
}

.bookingPanel {
    @media (--viewportLarge) {
        display: block;
        margin-top: 79px;
        /* 
        margin-left: 60px;
        border-left-style: solid;
        border-left-width: 1px;
        border-left-color: var(--matterColorNegative); */

        padding-left: 60px;

        /* The calendar needs 312px */
        flex-basis: 312px;
        flex-shrink: 0;
    }
}

.sectionAvatar {
    /* Position (over the listing image)*/
    /* margin-left: 24px; */
    /* margin-top: -72px; */

    /* Rendering context to the same lavel as listing image */
    position: relative;

    /* Flex would give too much width by default. */
    width: auto;

    @media (--viewportMedium) {
        /* position: absolute;
        top: -112px; */
        margin-left: 0;
        margin-top: 0;
    }
}

.avatarMobile {
    display: flex;
    width: 96px;
    height: 96px;

    @media (--viewportMedium) {
        display: none;
        /* Hide the medium avatar from the bigger screens */
    }
}

.avatarDesktop {
    display: none;
    /* Hide the large avatar from the smaller screens */
    /* width: 152px;
    height: 152px; */

    @media (--viewportMedium) {
        display: flex;
    }
}

.initialsDesktop {
    font-family: 'poppins';
    font-size: 20px;
    font-weight: var(--fontWeightSemiBold);
    /* padding-bottom: 8px; */
    text-transform: uppercase;
}

.sectionHeading {
    margin-top: 22px;
    margin-bottom: 34px;

    @media (--viewportMedium) {
        display: flex;
        margin-bottom: 31px;
    }
}

.desktopPriceContainer {
    display: none;

    @media (--viewportLarge) {
        /* Layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        margin: 0 48px 0 0;
        padding: 0;
    }
}

.desktopPriceValue {
    /* Font */
    @apply --marketplaceModalTitleStyles;
    color: var(--marketplaceColor);

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 1px;
    }
}

.desktopPerUnit {
    /* Font */
    @apply --marketplaceH5FontStyles;
    color: var(--matterColor);

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.heading {
    margin-bottom: 33px;
    padding: 0 24px;

    @media (--viewportMedium) {
        margin-bottom: 0;
        padding: 0;
    }
}

.title {
    /* Font */
    @apply --marketplaceModalTitleStyles;
    color: var(--matterColor);

    /* Layout */
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 2px;
    }
}

.activity {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.activityDivider {
    content: '•';
    margin: auto 5px;
}

.averageRating {
    margin-right: 3px;
}

.icon {
    width: 18px;
    
    fill: var(--matterColorNegative);
    /* margin-bottom: 5px; */
    margin-right: 5px;
}
.starIcon{
        margin-bottom: 5px;

}
.author {
    width: 100%;
    @apply --marketplaceH5FontStyles;
    margin-top: 7px;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
        color: var(--matterColor);
    }
}

.authorNameLink {
    color: var(--matterColor);
}

.contactWrapper {
    display: none;

    @media (--viewportMedium) {
        display: inline;
    }
}

.separator {
    margin: 0 6px;
}

.contactLink {
    @apply --marketplaceLinkStyles;
    @apply --marketplaceH5FontStyles;
    margin: 0;
}

.sectionDescription {
    padding: 0 24px;
    margin-bottom: 35px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 36px;
    }
}

.sectionDescriptionInfoContainer {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}

.sectionDescriptionInfo {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-right: 25px;

    display: inline-flex;
}

.sectionDescriptionInfo>img {
    margin-top: -1px;
}

.filterBrightness {
    height: 24px;

    filter: brightness(0.5);
    margin-right: 5px;
    width: 20px;

}

.descriptionTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 13px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 12px;
    }
}

.sectionFeatures {
    padding: 0 24px;
    margin-bottom: 32px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 52px;
    }
}

.firstIcon {
    height: 24px;
    filter: brightness(0.5);
    margin-right: 10px;
    margin-top: -10px;
}

.featuresTitle {
    /* Font */
    @apply --marketplaceH2FontStyles;
    color: var(--grey3);

    margin-top: 0;
    margin-bottom: 16px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 30px;
    }
}

.description {
    margin-top: 24px;
    margin-bottom: 0;
    font-weight: var(--fontWeightRegular);

    /* Preserve newlines, but collapse other whitespace */
    white-space: pre-line;
}

.sectionMap {
    padding: 0 24px;
    margin-bottom: 35px;

    @media (--viewportMedium) {
        padding: 0;
    }
}

.locationTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 20px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 28px;
    }
}

.sectionReviews {
    padding: 0 24px;
    margin-bottom: 5px;

    @media (--viewportMedium) {
        padding: 0;
    }

    @media (--viewportLarge) {
        margin-bottom: 50px;
    }
}

.reviewsHeading {
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin: 52px 0 20px 0;

    @media (--viewportMedium) {
        margin: 53px 0 27px 0;
    }

    @media (--viewportLarge) {
        margin: 50px 0 26px 0;
    }
}

.sectionHost {
    position: relative;
    padding: 30px 24px 0 24px;
    margin-bottom: 5px;

    @media (--viewportMedium) {
        padding: 30px 0 0 0;
    }

    @media (--viewportLarge) {
        margin-bottom: 3px;
    }
}

.yourHostHeading {
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin: 54px 0 23px 0;

    @media (--viewportMedium) {
        margin: 53px 0 27px 0;
    }

    @media (--viewportLarge) {
        margin: 53px 0 27px 0;
    }
}

.enquiryModalContent {
    flex-grow: 1;
    display: flex;
}

.enquiryForm {
    flex-grow: 1;
    margin: 70px 0 100px 0;

    @media (--viewportMedium) {
        display: flex;
        flex-direction: column;
        min-height: 400px;
        margin: 0;
    }
}

.enquirySubmitButtonWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;
    background-color: var(--matterColorLight);

    @media (--viewportMedium) {
        position: static;
        padding: 0;
        margin-top: auto;
    }
}

.map {
    /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
    height: calc(100vh - 193px);
    width: 100%;

    /* Static map: dimensions are 640px */
    max-width: 640px;
    max-height: 640px;
    background-color: #eee;
    padding: 0;
    border: 0;
    cursor: pointer;

    @media (--viewportMedium) {
        height: 75vh;
    }

    @media (--viewportLarge) {
        height: 417px;
    }
}

.longWord {
    /* fallback option */
    word-break: break-all;
    /* use break-word if available */
    word-break: break-word;
    hyphens: auto;
}

.hostSection {
    display: flex;
    align-content: center;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
}

.sectionFeatures,
.sectionReviews,
.sectionMap {
    border-top: 1px solid #d9d9d9;
    padding-top: 30px;
}


.returnToInbox {
    position: fixed;
    right: 20px;
    z-index: 2;
    padding: 2px 8px;
    background-color: white;
    border-radius: 4px;
    margin-top: 8px;

    @media (--viewportMedium) {
        right: 36px;
    }

    @media (min-width: 1146px) {
        right: calc(((100vw - 1128px)/2) + 36px)
    }

}



.whatsappBtn {
    z-index: 99;

    & > div {
        margin-bottom: 40px;

        @media (--viewportLarge) {
            margin-bottom: auto;
        }
    }
}

.chatWithHostBtn {
    border-radius: 2px;
    min-height: 50px;
    background-color: #A020F0;
    color: white;
    font-weight: 400;
    margin-top: 12px;
    &:focus{
        background-color:#880ED4 !important;
    }
    &:hover{
        background-color:#880ED4;
    }
}